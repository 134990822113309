import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Alert, Form } from "react-bootstrap";
import { setToken } from "../utils/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ username: email, password }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        if (response.status === 403 && data.detail === "Your account has been blocked. Contact support.") {
          setError("❌ Ваш аккаунт заблокирован. Обратитесь в поддержку.");
        } else {
          setError("❌ Неправильный логин или пароль");
        }
        return;
      }

      setToken(data.access_token, data.refresh_token);
      navigate("/");
    } catch (err) {
      setError("❌ Ошибка подключения к серверу");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <Card style={{ width: "400px", padding: "20px" }}>
        <Card.Body>
          <Card.Title className="text-center">Вход в систему</Card.Title>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="text-center mt-3">
                <a href="/registration">Нет аккаунта? Зарегистрироваться</a>
              </div>
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Войти
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
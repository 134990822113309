import React, { useState, useEffect } from "react";
import { Card, Button, Form, Container, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "../utils/auth";
import { IconCaretLeft } from "@tabler/icons-react";

const Profile = () => {
  const [formData, setFormData] = useState({ firstname: "", lastname: "", country: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({ old_password: "", new_password: "", confirm_password: "" });

  useEffect(() => {
    fetchWithAuth("/api/auth/profile")
      .then((res) => res.json())
      .then((data) => setFormData({ firstname: data.firstname, lastname: data.lastname, country: data.country }))
      .catch(() => setError("Ошибка загрузки профиля"));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setMessage("");
  setError("");

  const cleanData = {
    firstname: formData.firstname.trim(),
    lastname: formData.lastname.trim(),
    country: formData.country.trim(),
  };

  console.log("Отправляемые данные:", cleanData); // ✅ Логируем, что реально отправляем

  try {
    const response = await fetchWithAuth("/api/auth/update-profile", {
      method: "PUT",
      body: JSON.stringify(cleanData), // ✅ Отправляем ЧИСТЫЙ JSON
    });

    if (response.ok) {
      setMessage("✅ Профиль обновлен успешно");
    } else {
      const errorData = await response.json();
      setError(`❌ Ошибка: ${errorData.detail}`);
      console.error("Ошибка API:", errorData); // ✅ Выводим ошибку API
    }
  } catch {
    setError("❌ Ошибка сети");
  }
};


const handleChangePassword = async (e) => {
  e.preventDefault();
  setMessage("");
  setError("");

  const cleanPasswordData = {
    old_password: passwordData.old_password.trim(),
    new_password: passwordData.new_password.trim(),
    confirm_password: passwordData.confirm_password.trim(),
  };

  try {
    const response = await fetchWithAuth("/api/auth/change-password", {
      method: "PUT",
      body: JSON.stringify(cleanPasswordData), // ✅ Отправляем ЧИСТЫЙ JSON
    });

    if (response.ok) {
      setMessage("✅ Пароль успешно изменен");
      setPasswordData({ old_password: "", new_password: "", confirm_password: "" });
    } else {
      const errorData = await response.json();
      setError(`❌ Ошибка: ${errorData.detail}`);
      console.error("Ошибка API:", errorData);
    }
  } catch {
    setError("❌ Ошибка сети");
  }
};





  return (
<>

              <Card>
<Card.Header className="d-flex align-items-center gap-2">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
  <h3 className="card-title mb-0">Настройки профиля</h3>
</Card.Header> 
             <Card.Body>
               {error && <Alert variant="danger">{error}</Alert>}
  {message && <Alert variant="success">{message}</Alert>}
                   <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Имя</Form.Label>
              <Form.Control type="text" name="firstname" value={formData.firstname} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Страна</Form.Label>
              <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
                <option value="">Выберите страну</option>
                <option value="USA">USA</option>
                <option value="Canada">Canada</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Germany">Germany</option>
                <option value="France">France</option>
                <option value="Russia">Russia</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">Сохранить изменения</Button>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h5>Смена пароля</h5>
          <Form onSubmit={handleChangePassword}>
            <Form.Group className="mb-3">
              <Form.Label>Старый пароль</Form.Label>
              <Form.Control type="password" name="old_password" value={passwordData.old_password} onChange={handlePasswordChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Новый пароль</Form.Label>
              <Form.Control type="password" name="new_password" value={passwordData.new_password} onChange={handlePasswordChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Подтвердите новый пароль</Form.Label>
              <Form.Control type="password" name="confirm_password" value={passwordData.confirm_password} onChange={handlePasswordChange} required />
            </Form.Group>
            <Button variant="danger" type="submit">Изменить пароль</Button>
          </Form>
        </Card.Body>
      </Card>
      </>
  );
};

export default Profile;
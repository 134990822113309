import React from "react";
import { Card, Container } from "react-bootstrap";

const Contactus = () => {
  return (
      <Card>
        <Card.Body>
          <h1 className="text-center">Contact us</h1>
          <p className="text-center text-muted">
            
          </p>
        </Card.Body>
      </Card>
  );
};

export default Contactus;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Alert, Table, Form, Container, Row, Col, Pagination } from "react-bootstrap";
import { fetchWithAuth } from "../utils/auth";
import { IconCaretLeft } from "@tabler/icons-react";


const IndexingControl = () => {
    const [sortBy, setSortBy] = useState("creation_date");
    const [sortOrder, setSortOrder] = useState("desc");

    const handleSort = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }
    };
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);

  
const getPaginationPages = (current, total) => {
  const delta = 2;
  const pages = new Set();

  pages.add(1);
  pages.add(total);

  for (let i = current - delta; i <= current + delta; i++) {
    if (i > 1 && i < total) {
      pages.add(i);
    }
  }

  const sorted = Array.from(pages).sort((a, b) => a - b);

  const result = [];
  for (let i = 0; i < sorted.length; i++) {
    result.push(sorted[i]);

    if (i < sorted.length - 1 && sorted[i + 1] - sorted[i] > 1) {
      result.push("...");
    }
  }

  return result;
};

  useEffect(() => {
    fetchProjects();
  }, [perPage, currentPage, searchQuery, sortBy, sortOrder]);


const fetchProjects = async () => {
  setLoading(true);
  try {
    let url = `/api/projects?limit=${Number(perPage)}&page=${Number(currentPage)}`;

    if (searchQuery) {
      url += `&search=${encodeURIComponent(searchQuery)}`;
    }

    if (sortBy) {
      url += `&sort_by=${sortBy}&sort_order=${sortOrder}`;
    }

    const response = await fetchWithAuth(url);
    if (!response.ok) throw new Error("Ошибка загрузки проектов");

    const data = await response.json();
    console.log("DEBUG DATA >>>", data);

    setProjects(Array.isArray(data.projects) ? data.projects : []);
    setTotalCount(typeof data.total_count === "number" ? data.total_count : 0);
    setTotalPages(Math.ceil((typeof data.total_count === "number" ? data.total_count : 1) / perPage));
  } catch (err) {
    console.error(err);
    setError("Ошибка загрузки данных");
    setProjects([]);
    setTotalPages(1);
    setTotalCount(0);
  } finally {
    setLoading(false);
  }
};;


  const handleDeleteSelected = async () => {
    if (selectedProjects.length === 0) return;
    const isConfirmed = window.confirm("Вы уверены, что хотите удалить выбранные проекты?");
    if (!isConfirmed) return;
    try {
      const response = await fetch("/api/projects", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ project_ids: selectedProjects }),
      });
      if (!response.ok) throw new Error("Ошибка удаления проектов");
      setSelectedProjects([]);
      fetchProjects();
    } catch (error) {
      console.error("Ошибка при удалении:", error);
    }
  };

  return (
      <Card>
            {error && <Alert variant="danger">{error}</Alert>}
            <Card.Header className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center gap-2 flex-grow-1">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
          <h3 className="card-title">Indexing Control</h3>
            </div>
  <div className="d-flex gap-2">
          <Button variant="primary" onClick={() => navigate("/indexing/add")}>Add Project</Button>
            </div>
</Card.Header>
        <Card.Body className="border-bottom py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-secondary">
              Show
              <Form.Control as="select" size="sm" value={perPage} onChange={(e) => setPerPage(Number(e.target.value))} className="mx-2 d-inline-block w-auto">
                {[10, 50, 100, 500, 1000].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
              entries
            </div>
            <div className="ms-auto text-secondary">
              Search:
              <Form.Control type="text" size="sm" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="ms-2 d-inline-block w-auto" placeholder="Search projects..." />
            </div>
          </div>
        </Card.Body>
        <div className="table-responsive">
          <Table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th className="w-1"><Form.Check type="checkbox" onChange={(e) => setSelectedProjects(e.target.checked ? projects.map(p => p.id) : [])} /></th>
                <th style={{ cursor: "pointer" }} onClick={() => handleSort("name")}>Project Name</th>
                <th className="text-center" style={{ opacity: 0.5 }}><div>Check</div>Status</th>
                <th style={{ cursor: "pointer" }} onClick={() => handleSort("nb_links")}>Nb Links</th>
                <th style={{ cursor: "pointer" }} onClick={() => handleSort("indexed_percent")}>Indexed %</th>
                <th className="text-center"><div>Indexing in</div> Progress</th>
                <th
  onClick={() => handleSort("creation_date")}
  style={{ cursor: "pointer" }}
  className="text-center"
>
  <div>Creation</div>Date {sortBy === "creation_date" && (sortOrder === "asc" ? "🔼" : "🔽")}
</th>
                <th
  onClick={() => handleSort("update_date")}
  style={{ cursor: "pointer" }}
  className="text-center"
>
  <div>Update</div>Date {sortBy === "update_date" && (sortOrder === "asc" ? "🔼" : "🔽")}
</th>
              </tr>
            </thead>
            <tbody>
              {projects.length === 0 ? (
                <tr><td colSpan="8" className="text-center py-4">Нет данных</td></tr>
              ) : (
                projects.map((project) => (
                  <tr key={project.id}>
                    <td>
                      <Form.Check type="checkbox" checked={selectedProjects.includes(project.id)}
                        onChange={(e) => {
                          setSelectedProjects((prev) =>
                            e.target.checked ? [...prev, project.id] : prev.filter((id) => id !== project.id)
                          );
                        }}
                      />
                    </td>
                    <td style={{ whiteSpace: "normal", wordBreak: "break-word", minWidth: "300px", maxWidth: "100%", flexGrow: 1 }}><a href={`/project/${project.id}`} className="text-reset text-primary">{project.name}</a></td>
                    <td className="text-center">{project.check_status === "Pending" ? <span className="badge bg-secondary me-1"></span> : project.check_status === "Checked" ? <span className="badge bg-success me-1"></span> : null} {project.check_status}</td>
                    <td className="text-center">{project.nb_links ?? 0}</td>
                    <td className="text-center">{project.indexed_percent}%</td>
                    <td className="text-center">{project.indexing_in_progress}</td>
                    <td className="text-center"><div>{new Date(project.creation_date).toLocaleDateString()}</div><div>{new Date(project.creation_date).toLocaleTimeString()}</div></td>
                    <td className="text-center"><div>{new Date(project.update_date).toLocaleDateString()}</div><div>{new Date(project.update_date).toLocaleTimeString()}</div></td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <Button variant="danger" onClick={handleDeleteSelected} disabled={selectedProjects.length === 0}>
            Remove
          </Button>
          
          
          <Pagination className="m-0">
            <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>«</Pagination.Prev>
            {getPaginationPages(currentPage, totalPages).map((page, i) =>
              page === "..." ? (
                <Pagination.Ellipsis key={`ellipsis-${i}`} disabled />
              ) : (
                <Pagination.Item
                  key={`page-${page}`}
                  active={page === currentPage}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Pagination.Item>
              )
            )}
            <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>»</Pagination.Next>
          </Pagination>


        </Card.Footer>
      </Card>
  );
};

export default IndexingControl;

// Footer.js - Обновленный дизайн с Tabler
import React from 'react';

function Footer() {
  return (
    <footer className="footer footer-transparent d-print-none">
      <div className="container-xl">
        <span className="text-muted">&copy; {new Date().getFullYear()} IndexBooster. All rights reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
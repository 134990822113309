import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Alert, Table, Form, Container, Pagination } from "react-bootstrap";
import { fetchWithAuth, getToken } from "../utils/auth";
import { IconCaretLeft } from "@tabler/icons-react";

const UserProjects = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());

  useEffect(() => {
    console.log("🔄 Обнаружено изменение маршрута, перезагружаем данные...");
    setProjects([]);
    setError(null);
    setLoading(true);
  }, [userId]);

  useEffect(() => {
    if (!userId || !isAuthenticated) return;
    fetchProjects();
  }, [userId, perPage, currentPage, isAuthenticated]);

  const fetchProjects = async () => {
    if (!userId) {
      setError("Ошибка: ID пользователя отсутствует!");
      return;
    }
    
    setLoading(true);
    try {
      console.log(`🚀 Запрашиваем проекты для пользователя ID: ${userId}`);
      
      const response = await fetchWithAuth(`/api/auth/users/${userId}/projects?limit=${perPage}&page=${currentPage}`);
      if (!response.ok) throw new Error("Ошибка загрузки проектов пользователя");

      const data = await response.json();
      console.log("✅ API ответ:", data);

      if (!data || !Array.isArray(data.projects)) {
        throw new Error("Некорректный формат данных от API");
      }

      setProjects(data.projects);
      setTotalPages(Math.ceil((data.total_count || 1) / perPage));
      setError(null);
    } catch (err) {
      console.error("❌ Ошибка загрузки проектов:", err.message);
      setError(err.message);
      setProjects([]);
    } finally {
      setLoading(false);
    }
  };

  if (!userId) return <Alert variant="danger">Ошибка: ID пользователя отсутствует!</Alert>;

  return (
<> 
      <Card>
            {error && <Alert variant="danger">{error}</Alert>}

<Card.Header className="d-flex align-items-center gap-2">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
  <h3 className="card-title mb-0">User Projects</h3>
</Card.Header>
        <Card.Body className="border-bottom py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-secondary">
              Show
              <Form.Control as="select" size="sm" value={perPage} onChange={(e) => setPerPage(Number(e.target.value))} className="mx-2 d-inline-block w-auto">
                {[10, 50, 100, 500, 1000].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
              entries
            </div>
          </div>
        </Card.Body>
        <div className="table-responsive">
          <Table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Check Status</th>
                <th>Nb Links</th>
                <th>Indexed %</th>
                <th>Indexing in Progress</th>
                <th>Creation Date</th>
                <th>Update Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr><td colSpan="7" className="text-center py-4">Загрузка...</td></tr>
              ) : projects.length === 0 ? (
                <tr><td colSpan="7" className="text-center py-4">У пользователя нет проектов</td></tr>
              ) : (
                projects.map((project) => {
                  console.log("📌 Данные проекта (проверка полей):", project);
                  return (
                    <tr key={project.id}>
                      <td>
                        <a href={`/project/${project.id}`} className="text-reset text-primary">
                          {project.name || "N/A"}
                        </a>
                      </td>
                      <td>{project.check_status || "N/A"}</td>
                      <td>{project.nb_links ?? 0}</td>
                      <td>{project.indexed_percent ? `${project.indexed_percent}%` : "0%"}</td>
                      <td>{project.indexing_in_progress ? "Yes" : "No"}</td>
                      <td>{project.creation_date ? new Date(project.creation_date).toLocaleString() : "N/A"}</td>
                      <td>{project.update_date ? new Date(project.update_date).toLocaleString() : "N/A"}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <Pagination className="m-0">
            <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Prev</Pagination.Prev>
            {[...Array(totalPages).keys()].map(num => (
              <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                {num + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>Next</Pagination.Next>
          </Pagination>
        </Card.Footer>
      </Card></> 
  );
};

export default UserProjects;

import React from "react";
import { Card, CardBody, CardTitle, Button } from "react-bootstrap";

export default function NotFound() {
  return (
    <div className="container py-5 text-center">
      <Card>
        <CardBody>
          <CardTitle as="h2">404 - Страница не найдена</CardTitle>
          <p>Такой страницы не существует.</p>
          <Button variant="primary" onClick={() => (window.location.href = "/")}>Вернуться на главную</Button>
        </CardBody>
      </Card>
    </div>
  );
}

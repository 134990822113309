import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Form, Alert, Container } from "react-bootstrap";
import { IconCaretLeft } from "@tabler/icons-react";

const EditProject = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);

    useEffect(() => {
        fetchProject();
    }, [id]);

    const fetchProject = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const projectResponse = await fetch(`/api/projects/${id}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (!projectResponse.ok) {
                if (projectResponse.status === 403 || projectResponse.status === 404) {
                    navigate("/404");
                    return;
                }
                throw new Error("Ошибка загрузки проекта");
            }
            const projectData = await projectResponse.json();
            setProject(projectData);
            setProjectName(projectData.name);
            setProjectDescription(projectData.description || "");
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditProject = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`/api/projects/${id}`, {
                method: "PATCH",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: projectName, description: projectDescription }),
            });

            if (!response.ok) {
                throw new Error("Ошибка редактирования проекта");
            }

            setSuccessMessage("Проект успешно обновлен!");
            setTimeout(() => {
                navigate(`/project/${id}`);
            }, 1500);
        } catch (err) {
            setError(`Ошибка при редактировании проекта: ${err.message}`);
        }
    };

    return (
<>
                            {loading && <p>Загрузка...</p>}
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
     {project && (
                <Card>
                <Card.Header className="d-flex align-items-center gap-2">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
  <h3 className="card-title mb-0">Edit Project</h3>
</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleEditProject}>
                            <Form.Group className="mb-3">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={projectName} 
                                    onChange={(e) => setProjectName(e.target.value)} 
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    value={projectDescription} 
                                    onChange={(e) => setProjectDescription(e.target.value)} 
                                    rows={3}
                                />
                            </Form.Group>

                            <div className="d-flex justify-content-between">
                                <Button variant="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}</> 
    );
};

export default EditProject;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { refreshToken, logout } from "../utils/auth";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        let token = localStorage.getItem("token");
        let refreshTokenValue = localStorage.getItem("refresh_token");

        console.log("Проверяем токены...");
        console.log("Текущий access_token:", token);
        console.log("Текущий refresh_token:", refreshTokenValue);

        if (!token) {
          console.warn("Access токен отсутствует. Попытка обновить...");

          // Если рефреш-токена нет, сразу выходим
          if (!refreshTokenValue) {
            console.error("Рефреш-токен отсутствует в localStorage. Логаут...");
            logout();
            setIsAuthenticated(false);
            return;
          }

          try {
            console.log("Отправляем запрос на обновление токена...");

            const response = await fetch("/api/auth/refresh", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ refresh_token: refreshTokenValue }), // Гарантируем, что не отправим `null`
            });

            if (!response.ok) {
              const errorText = await response.text();
              console.error("Ошибка обновления токена:", errorText);
              logout();
              setIsAuthenticated(false);
              return;
            }

            const data = await response.json();
            if (!data.access_token) {
              console.error("Ответ сервера не содержит нового токена. Логаут...");
              logout();
              setIsAuthenticated(false);
              return;
            }

            console.log("Обновленный токен:", data.access_token);
            localStorage.setItem("token", data.access_token);
            setIsAuthenticated(true);
          } catch (error) {
            console.error("Ошибка при запросе обновления токена:", error);
            logout();
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Ошибка аутентификации:", error);
        logout();
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) return null; // Пока идет проверка, не рендерим ничего

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;

import { IconRefresh, IconCaretLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Table, Form, Container, Row, Col, Alert, Pagination } from "react-bootstrap";

const ProjectView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const [urlsPerPage, setUrlsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [urls, setUrls] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortBy, setSortBy] = useState("creation_date");
    const [sortOrder, setSortOrder] = useState("desc");
    const [refreshDisabled, setRefreshDisabled] = useState({});

    useEffect(() => {
        fetchProject();
    }, [id]);

    useEffect(() => {
        fetchUrls();
    }, [currentPage, urlsPerPage, sortBy, sortOrder]);

    const fetchProject = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const projectResponse = await fetch(`/api/projects/${id}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (!projectResponse.ok) {
                if (projectResponse.status === 403 || projectResponse.status === 404) {
                    navigate("/404");
                    return;
                }
                throw new Error("Ошибка загрузки проекта");
            }
            const projectData = await projectResponse.json();
            setProject(projectData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    
    
const handleRecheck = async (urlId) => {
  if (refreshDisabled[urlId]) return;
  try {
    setRefreshDisabled(prev => ({ ...prev, [urlId]: true }));
    const token = localStorage.getItem("token");
    const response = await fetch(`/api/projects/${id}/urls/${urlId}/recheck`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    if (!response.ok) throw new Error("Ошибка повторной проверки");

    // локально помечаем как pending
    setUrls(prev =>
      prev.map((url) =>
        url.id === urlId ? { ...url, indexing_status: "pending" } : url
      )
    );

    // снимаем блок через 60 сек
    setTimeout(() => {
      setRefreshDisabled(prev => {
        const copy = { ...prev };
        delete copy[urlId];
        return copy;
      });
    }, 60000);
  } catch (e) {
    console.error("Ошибка при recheck:", e);
  }
};


    const fetchUrls = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const urlsResponse = await fetch(`/api/projects/${id}/urls?limit=${urlsPerPage}&page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (!urlsResponse.ok) throw new Error("Ошибка загрузки ссылок");
            const urlsData = await urlsResponse.json();
            console.log("🔥 URLs from server:", urlsData.urls);
            setUrls(urlsData.urls || []);
            setTotalCount(urlsData.total_count || 0);
            setTotalPages(Math.ceil(urlsData.total_count / urlsPerPage));
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteSelected = async () => {
        if (selectedUrls.length === 0) return;

        const confirmDelete = window.confirm("Вы уверены, что хотите удалить выбранные ссылки?");
        if (!confirmDelete) return;

        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`/api/projects/${id}/delete-links`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ link_ids: selectedUrls })
            });

            if (!response.ok) throw new Error("Ошибка удаления ссылок");

            setSelectedUrls([]);
            fetchUrls();
        } catch (error) {
            console.error("Ошибка при удалении:", error);
        }
    };

    const renderSortHeader = (label, field) => (
        <th style={{ cursor: "pointer" }} onClick={() => {
        if (sortBy === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortBy(field);
            setSortOrder("asc");
        }
    }}>
            {label} {sortBy === field && (sortOrder === "asc" ? "▲" : "▼")}
        </th>
    );

    return (
        <>
            {loading && <p>Загрузка...</p>}
            {error && <Alert variant="danger">{error}</Alert>}

            {project && (<Card>
                
<Card.Header className="d-flex flex-wrap justify-content-between align-items-start gap-2">
  <div className="d-flex align-items-center gap-2 flex-grow-1">
    <Button variant="light" size="sm" onClick={() => navigate("/indexing")}>
       <IconCaretLeft size={18} />
    </Button>
    <h3 className="card-title text-break m-0" style={{ wordBreak: "break-word", minWidth: 0, maxWidth: "100%" }}>
      {project.name}
    </h3>
  </div>
  <div className="d-flex gap-2">
    <Button onClick={() => navigate(`/project/${id}/add-links`)} variant="primary">Add Links</Button>
    <Button onClick={() => navigate(`/project/${id}/edit`)} variant="secondary">Edit Project</Button>
  </div>
</Card.Header>

                {project.description && (
                    <Card.Body className="card-body">
                        <p>{project.description}</p>
                    </Card.Body>
                )}
                
<Card.Body className="border-bottom py-3">
    <div className="d-flex justify-content-between align-items-center">
        <div className="text-secondary">
            Show
            <Form.Control as="select" size="sm" value={urlsPerPage} onChange={(e) => setUrlsPerPage(Number(e.target.value))} className="mx-2 d-inline-block w-auto">
                {[10, 50, 100, 500, 1000].map((v) => <option key={v} value={v}>{v}</option>)}
            </Form.Control>
            entries
        </div>
        <div className="text-end ms-auto">
            <Form.Control type="text" placeholder="Поиск по URL..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} size="sm" style={{ maxWidth: '300px' }} />
        </div>
    </div>
</Card.Body>

                <div className="table-responsive">
                    <Table className="table card-table table-vcenter text-nowrap datatable">
                        <thead>
                            <tr>
                                <th className="w-1">
                                    <Form.Check type="checkbox" onChange={(e) => setSelectedUrls(e.target.checked ? urls.map(u => u.id) : [])} />
                                </th>
                                {renderSortHeader("URL", "url")}
                                {renderSortHeader("Indexable?", "indexing_status")}
                                {renderSortHeader("Creation Date", "creation_date")}
                                <th className="text-center">Status</th>
                                {renderSortHeader("Snippet Info", "snippet_info")}
                            </tr>
                        </thead>
                        <tbody>
                            {urls.filter(url => url.url.toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? (
                                <tr><td colSpan="6" className="text-center py-4">Нет данных</td></tr>
                            ) : (
                                urls.filter(url => url.url.toLowerCase().includes(searchQuery.toLowerCase())).map((url) => (
                                    <tr key={url.id}>
                                        <td>
                                            <Form.Check type="checkbox" checked={selectedUrls.includes(url.id)}
                                                onChange={(e) => {
                                                    setSelectedUrls((prev) =>
                                                        e.target.checked ? [...prev, url.id] : prev.filter((id) => id !== url.id)
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td style={{ whiteSpace: "normal", wordBreak: "break-word", minWidth: "300px", maxWidth: "100%", flexGrow: 1 }}>
                                            <a href={url.url} target="_blank" rel="noopener noreferrer" className="text-dark text-decoration-none">
                                                {url.url}
                                            </a>
                                        </td>
                                        
<td className="text-center">
  {url.indexing_status === "pending" ? (
    <span className="text-warning">Pending...</span>
  ) : url.indexing_status?.startsWith("YES") ? (
    <span className="text-success">{url.indexing_status}</span>
  ) : (
    <span className="text-danger d-inline-flex align-items-center gap-1">
      <Button
        variant="link"
        size="sm"
        className="p-0 m-0 text-danger"
        onClick={() => handleRecheck(url.id)}
        disabled={refreshDisabled[url.id]}
        title="Повторно проверить"
      >
        <IconRefresh size={14} />
      </Button>
      {url.indexing_status}
    </span>
  )}
</td>

                                        <td className="text-center">{new Date(url.creation_date).toLocaleString()}</td>
                                        <td className="text-center">Pending</td>
                                        <td className="text-center" style={{ minWidth: "375px", whiteSpace: "normal", wordBreak: "break-word" }}>
                                            {url.snippet_info
                                                ? url.indexing_status.startsWith("NO (301") || url.indexing_status.startsWith("NO (302") ||
                                                    url.indexing_status.startsWith("NO (303") || url.indexing_status.startsWith("NO (304") ||
                                                    url.indexing_status.startsWith("NO (307")
                                                    ? `Редирект на другой сайт, уберите 301 редирект на ${url.snippet_info?.includes("Redirected to ")
                                                        ? url.snippet_info.split("Redirected to ")[1]
                                                        : "неизвестный URL"}`
                                                    : url.snippet_info
                                                : "Ожидание проверки"}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
                <Card.Footer className="d-flex align-items-center justify-content-between">
                    <Button variant="danger" onClick={handleDeleteSelected} disabled={selectedUrls.length === 0}>
                        Delete Selected
                    </Button>
                    
<ul className="m-0 pagination">
    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <a className="page-link" role="button" onClick={() => setCurrentPage(currentPage - 1)}>
            <span aria-hidden="true">«</span><span className="visually-hidden">Previous</span>
        </a>
    </li>
    {(() => {
        const pages = [];
        const range = [];
        for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
            range.push(i);
        }
        if (currentPage - 2 > 2) range.unshift("...");
        if (currentPage + 2 < totalPages - 1) range.push("...");
        range.unshift(1);
        if (totalPages > 1) range.push(totalPages);
        return range;
    })().map((page, idx) => (
        <li key={idx} className={`page-item ${page === currentPage ? "active" : page === "..." ? "disabled" : ""}`}>
            {page === "..." ? (
                <span className="page-link"><span aria-hidden="true">…</span><span className="visually-hidden">More</span></span>
            ) : (
                <a className="page-link" role="button" onClick={() => setCurrentPage(page)}>{page}</a>
            )}
        </li>
    ))}
    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <a className="page-link" role="button" onClick={() => setCurrentPage(currentPage + 1)}>
            <span aria-hidden="true">»</span><span className="visually-hidden">Next</span>
        </a>
    </li>
</ul>

                </Card.Footer>
            </Card>)}
        </>
    );
};

export default ProjectView;

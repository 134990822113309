import React from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({ children, logout }) => {
  return (
    <div>
      <Sidebar logout={logout} />
      <div className="page-wrapper">
        <main className="p-4 bg-gray-100 flex-grow-1 overflow-auto">{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
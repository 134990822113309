import React from "react";
import { useNavigate } from "react-router-dom";



const Settings = () => {
  return <h1 className="text-2xl">⚙️ Settings</h1>;
};

export default Settings;

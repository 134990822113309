import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Alert, Form, Container } from "react-bootstrap";
import { IconCaretLeft } from "@tabler/icons-react";

const AddLinks = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [urlList, setUrlList] = useState("");
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // ✅ Нормализация URL (удаление `www.`, приведение к нижнему регистру, удаление `/` в конце)
const normalizeURL = (url) => {
    try {
        const parsedUrl = new URL(url.trim());

        // Приведение к единому формату
        let cleanUrl = parsedUrl.protocol + "//" + parsedUrl.hostname.toLowerCase() + parsedUrl.pathname + parsedUrl.search; // ✅ Добавили `parsedUrl.search`

        // ✅ Убираем `www.`
        cleanUrl = cleanUrl.replace("://www.", "://");

        // ✅ Убираем `/` в конце, если он не является единственным символом пути и нет параметров
        

        return cleanUrl;
    } catch {
        return null; // Если URL невалидный, вернуть null
    }
};

    // ✅ Валидация и удаление дубликатов
    const validateLinks = async () => {
        let linkArray = urlList
            .split(/\r?\n/) // Разбиваем на строки
            .map((link) => normalizeURL(link)) // ✅ Нормализация перед удалением дубликатов
            .filter((link) => link !== null); // Исключаем некорректные ссылки

        // ✅ Проверка загрузки файла
        if (file) {
            try {
                const fileContent = await file.text();
                const fileLinks = fileContent
                    .split(/\r?\n/)
                    .map((link) => normalizeURL(link))
                    .filter((link) => link !== null);

                // ✅ Объединяем и удаляем дубликаты после нормализации
                linkArray = [...new Set([...linkArray, ...fileLinks])];
            } catch (error) {
                setError("Ошибка чтения файла.");
                return null;
            }
        }

        // ✅ Гарантированное удаление дубликатов после всех проверок
        linkArray = [...new Set(linkArray)];

        if (linkArray.length > 2000) {
            setError(`Превышен лимит: максимум 2000 ссылок. Вы ввели ${linkArray.length}.`);
            return null;
        }

        return linkArray;
    };

    // ✅ Функция добавления ссылок в API
    const handleAddLinks = async () => {
        setError("");
        setSuccessMessage("");

        const uniqueLinks = await validateLinks();
        if (!uniqueLinks) return; // Если валидация провалилась

        if (uniqueLinks.length === 0) {
            setError("Введите хотя бы одну корректную ссылку.");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const addedLinks = [];
            const skippedLinks = [];

            for (const url of uniqueLinks) {
                const response = await fetch(`/api/projects/${id}/urls`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ url }),
                });

                if (response.ok) {
                    addedLinks.push(url);
                } else {
                    const errorData = await response.json().catch(() => null);
                    skippedLinks.push(url);
                    console.warn(`⚠️ Ошибка добавления URL ${url}: ${errorData?.detail || "Неизвестная ошибка"}`);
                }
            }

            // ✅ Выводим статус добавления ссылок
            if (addedLinks.length > 0) {
                navigate(`/project/${id}`);
                return;
                setSuccessMessage(
                    `✅ Успешно добавлено ${addedLinks.length} ссылок в очередь на индексацию.` +
                    (skippedLinks.length > 0 ? ` ⚠️ Пропущено ${skippedLinks.length} ссылок (дубликаты или ошибки).` : "")
                );
            } else {
                setError("❌ Ни одна ссылка не была добавлена (ошибки или дубликаты).");
            }

            // ✅ Очищаем поле после успешной отправки
            setUrlList("");
            setFile(null);
        } catch (error) {
            console.error("❌ Ошибка в `handleAddLinks()`:", error);
            setError(error.message);
        }
    };

    return (
            <Card>
<Card.Header className="d-flex align-items-center gap-2">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
  <h3 className="card-title mb-0">Добавить ссылки</h3>
</Card.Header>
                <Card.Body>
                    {error && <Alert variant="danger" style={{ whiteSpace: "pre-line" }}>{error}</Alert>}
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}

                    <Form>
                        <Form.Group>
                            <Form.Label>Введите ссылки (по одной в строке):</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={urlList}
                                onChange={(e) => setUrlList(e.target.value)}
                                rows="5"
                                placeholder={`https://example.com\nhttps://example2.com\nhttps://example3.com\nhttps://example4.com\nhttps://example5.com\nhttps://example6.com\n...`}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Загрузить файл (CSV/TXT, max 1MB):</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                accept=".csv,.txt"
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success" onClick={handleAddLinks}>Добавить ссылки</Button>
                </Card.Footer>
            </Card>
    );
};

export default AddLinks;

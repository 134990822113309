import React from "react";
import { Card, Container } from "react-bootstrap";

const Dashboard = () => {
  return (
      <Card>
        <Card.Body>
          <h1 className="text-center">Welcome to the Dashboard</h1>
          <p className="text-center text-muted">
            This is your main control panel where you can manage all your indexing tasks.
          </p>
        </Card.Body>
      </Card>
  );
};

export default Dashboard;
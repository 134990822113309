import React, { useState, useEffect } from "react";
import { fetchWithAuth } from "../utils/auth";
import { Card, Table, Button, Form, Modal, Alert } from "react-bootstrap";
import { IconCirclePlus, IconUserPlus, IconTrash, IconLock, IconLockOpen, IconCaretLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Users = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({ email: "", password: "", firstname: "", lastname: "", country: "" });
  const [selectedUser, setSelectedUser] = useState(null);
   const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [creditAmount, setCreditAmount] = useState(0);
  const [error, setError] = useState("");
  

  useEffect(() => {
   fetchWithAuth("/api/auth/users")
  .then((res) => res.json())
  .then((data) => {
    console.log("👀 Данные пользователей:", data);  // 🔍 Лог для проверки
    setUsers(data);
  })
  .catch(() => setError("Ошибка загрузки пользователей"));
  }, []);

  const addCredits = async (userId) => {
    if (creditAmount <= 0) {
      setError("Сумма должна быть больше 0!");
      return;
    }

    try {
      const response = await fetchWithAuth(`/api/auth/users/${userId}/credits`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: creditAmount }),
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(users.map((user) => (user.id === userId ? { ...user, credits: data.credits } : user)));
        setCreditAmount(0);
      } else {
        setError("Ошибка при обновлении кредитов");
      }
    } catch (err) {
      console.error("Ошибка пополнения кредитов:", err);
    }
  };

  const addUser = async () => {
    try {
      const response = await fetchWithAuth("/api/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser),
      });

      if (response.ok) {
        setShowModal(false);
        setNewUser({ email: "", password: "", firstname: "", lastname: "", country: "" });
        fetchWithAuth("/api/auth/users")
          .then((res) => res.json())
          .then((data) => setUsers(data));
      } else {
        setError("Ошибка при добавлении пользователя");
      }
    } catch (err) {
      console.error("Ошибка добавления пользователя:", err);
    }
  };

  const deleteUser = async (userId) => {
    if (!window.confirm("Вы уверены, что хотите удалить пользователя?")) return;
    try {
      const response = await fetchWithAuth(`/api/auth/users/${userId}`, { method: "DELETE" });
      if (response.ok) {
        setUsers(users.filter((user) => user.id !== userId));
      } else {
        alert("Ошибка при удалении пользователя");
      }
    } catch (error) {
      console.error("Ошибка удаления пользователя:", error);
    }
  };

  const toggleBlockUser = async (userId, isBlocked) => {
    try {
      const response = await fetchWithAuth(`/api/auth/users/${userId}/block`, {
        method: "PATCH",
        body: JSON.stringify({ blocked: !isBlocked }),
      });
      if (response.ok) {
        setUsers(users.map((user) => (user.id === userId ? { ...user, blocked: !isBlocked } : user)));
      } else {
        alert("Ошибка при изменении статуса пользователя");
      }
    } catch (error) {
      console.error("Ошибка блокировки пользователя:", error);
    }
  };

  return (
    <Card>
<Card.Header className="d-flex align-items-center gap-2">
  <div className="d-flex align-items-center gap-2 flex-grow-1">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
        <h3 className="card-title">Users</h3>
  </div>
  <div className="d-flex gap-2">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <IconUserPlus size={18} className="me-2" /> Добавить пользователя
        </Button>
          </div>
      </Card.Header>

      <Card.Body className="border-bottom">
        {error && <Alert variant="danger">{error}</Alert>}
        <Table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>Email</th>
              <th className="text-center">Имя</th>
              <th className="text-center">Фамилия</th>
              <th className="text-center">Страна</th>
              <th className="text-center">Роль</th>
              <th className="text-center">Кредиты</th>
              <th className="text-center">Проекты</th>
              <th className="text-center">Ссылки</th>
              <th className="text-center">Статус</th>
              <th className="text-center">Действия</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">Нет пользователей</td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.id}>
                  <td>                <Link to={`/user/${user.id}/projects`} className="text-primary">
                  {user.email}
                </Link></td>
                  <td className="text-center">{user.firstname}</td>
                  <td className="text-center">{user.lastname}</td>
                  <td className="text-center">{user.country}</td>
                  <td className="text-center">{user.role}</td>
                  <td className="text-center">{user.credits ?? 0}   <IconCirclePlus
    size={18}  // Размер иконки
    stroke={2} // Толщина линий
    className="text-success cursor-pointer"
    onClick={() => setSelectedUser(user)}
    title="Пополнить кредиты"
  /></td>
  <td className="text-center">{user.projects ?? 0}</td>
  <td className="text-center">{user.urls ?? 0}</td>
                                <td className="text-center">
  <span
    className={`status-indicator ${user.blocked ? "bg-danger" : "bg-success"}`}
    style={{
      display: "inline-block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      marginRight: "8px",
    }}
  ></span>
  {user.blocked ? "" : ""}
</td>
<td className="text-center">
  <button className="btn btn-icon btn-outline-danger me-2" onClick={() => deleteUser(user.id)}>
    <IconTrash size={20} />
  </button>
  <button className="btn btn-icon btn-outline-warning" onClick={() => toggleBlockUser(user.id, user.blocked)}>
    {user.blocked ? <IconLockOpen size={20} /> : <IconLock size={20} />}
  </button>
</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>

      {/* Модалка для пополнения кредитов */}
      <Modal show={!!selectedUser} onHide={() => setSelectedUser(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Пополнение кредитов</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Введите сумму</Form.Label>
            <Form.Control
              type="number"
              value={creditAmount}
              onChange={(e) => setCreditAmount(parseFloat(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedUser(null)}>Отмена</Button>
          <Button variant="success" onClick={() => addCredits(selectedUser.id)}>Пополнить</Button>
        </Modal.Footer>
      </Modal>

      {/* Модалка для добавления пользователя */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Добавить пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
            </Form.Group>
                        <Form.Group className="mb-2">
              <Form.Label>Пароль</Form.Label>
              <Form.Control type="password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Имя</Form.Label>
              <Form.Control type="text" value={newUser.firstname} onChange={(e) => setNewUser({ ...newUser, firstname: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control type="text" value={newUser.lastname} onChange={(e) => setNewUser({ ...newUser, lastname: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="country">
  <Form.Label>Страна</Form.Label>
  <Form.Control
    type="text"
    placeholder="Введите страну"
    value={country}
    onChange={(e) => setCountry(e.target.value)}
  />
</Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Отмена</Button>
          <Button variant="primary" onClick={addUser}>Добавить</Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Users;

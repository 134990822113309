import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Alert, Form, Container } from "react-bootstrap";
import { IconCaretLeft } from "@tabler/icons-react";

const AddProject = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const isValidURL = (url) => /^https?:\/\/[\w.-]+(?:\.[\w.-]+)+[/\w\-._~:?#[\]@!$&'()*+,;=]*$/.test(url);

  const validateLinks = () => {
    let linkArray = links
      .split(/\r?\n/) // Разбиваем по строкам (учитываем \r\n и \n)
      .map((link) => link.trim()) // Убираем пробелы
      .filter((link) => link.length > 0); // Исключаем пустые строки

    const invalidLinks = linkArray.filter((link) => !isValidURL(link));

    if (invalidLinks.length > 0) {
      setError(`Некорректные ссылки:\n${invalidLinks.join("\n")}`);
      return null;
    }

    return linkArray;
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    const trimmedName = name.trim();
    if (!trimmedName) {
        setError("Введите название проекта!");
        return;
    }

    let uniqueLinks = validateLinks();
    if (uniqueLinks === null) return;

    if (file) {
        try {
            const fileText = await file.text();
            const fileLinks = fileText
                .split(/\r?\n/)
                .map((link) => link.trim())
                .filter((link) => isValidURL(link));

            if (fileLinks.length === 0) {
                setError("Файл не содержит корректных ссылок.");
                return;
            }

            uniqueLinks = [...new Set([...uniqueLinks, ...fileLinks])]; // ✅ Убираем дубликаты
        } catch (error) {
            setError("Ошибка чтения файла");
            return;
        }
    }

    // ✅ Удаляем дубликаты (регистр не важен)
    uniqueLinks = [...new Set(uniqueLinks.map(link => link.toLowerCase()))];

    if (uniqueLinks.length > 2000) {
        setError(`Превышен лимит: максимум 2000 ссылок. Вы ввели ${uniqueLinks.length}.`);
        return;
    }

    try {
        const token = localStorage.getItem("token");

        // ✅ Отправляем сразу список ссылок, не добавляем их вручную через `/urls`
        const formData = new FormData();
        formData.append("name", trimmedName);
        formData.append("description", description.trim() || "");
        formData.append("links", uniqueLinks.join("\n")); // ✅ Передаем ссылки строкой с переносами

        console.log("📤 Отправка проекта (FormData):", formData); 

        const response = await fetch("/api/projects", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        console.log("🔄 Ответ сервера:", response.status); 

        if (!response.ok) {
            let errorMessage = "Ошибка при создании проекта.";
            try {
                const errorData = await response.json();
                console.log("❌ Ошибка сервера:", errorData); 
                if (errorData.detail) {
                    errorMessage = Array.isArray(errorData.detail)
                        ? errorData.detail.map(err => `${err.loc?.join(".") || "Ошибка"}: ${err.msg}`).join("\n")
                        : errorData.detail;
                }
            } catch {
                errorMessage = await response.text();
            }
            throw new Error(errorMessage);
        }

        const createdProject = await response.json();

        console.log("✅ Проект создан:", createdProject);

        setSuccessMessage("Проект создан, ссылки отправлены в очередь на индексацию!");
        setTimeout(() => navigate("/indexing"), 2000);
    } catch (error) {
        console.error("❌ Ошибка в `handleSubmit()`:", error);
        setError(error.message);
    }
};



  return (<>
      <Card>
        <Card.Header className="d-flex align-items-center gap-2">
          <Button variant="light" size="sm" onClick={() => navigate(-1)}>
        <IconCaretLeft size={18} />
      </Button>
      <h3 className="card-title mb-0">Добавить проект</h3>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger" style={{ whiteSpace: "pre-line" }}>{error}</Alert>}  {/* ✅ Исправлено для отображения ошибок с новой строки */}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Название проекта*</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Описание</Form.Label>
              <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Список ссылок</Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                value={links}
                onChange={(e) => setLinks(e.target.value)}
                placeholder={"https://example.com\nhttps://example2.com"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Загрузить файл (CSV/TXT, макс. 1MB)</Form.Label>
              <Form.Control type="file" accept=".csv,.txt" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Создать проект
            </Button>
          </Form>
        </Card.Body>
      </Card></>
  );
};

export default AddProject;

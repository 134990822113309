import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Alert, Table, Form, Container, Pagination } from "react-bootstrap";
import { IconCaretLeft } from "@tabler/icons-react";


const GooglebotLogs = () => {
  const [logs, setLogs] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  
  const getPaginationPages = (current, total) => {
    const delta = 2;
    const pages = new Set();

    pages.add(1);
    pages.add(total);

    for (let i = current - delta; i <= current + delta; i++) {
      if (i > 1 && i < total) {
        pages.add(i);
      }
    }

    const sorted = Array.from(pages).sort((a, b) => a - b);

    const result = [];
    for (let i = 0; i < sorted.length; i++) {
      result.push(sorted[i]);

      if (i < sorted.length - 1 && sorted[i + 1] - sorted[i] > 1) {
        result.push("...");
      }
    }

    return result;
  };

  useEffect(() => {
    fetchData();
    fetchStats();  }, [logsPerPage, currentPage, searchQuery]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let url = `/api/googlebot-logs?limit=${logsPerPage}&page=${currentPage}`;

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }
      if (searchQuery) {
        url += `&search=${encodeURIComponent(searchQuery)}`;
      }

      const response = await fetch(url, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (!response.ok) {
        if (response.status === 401) navigate("/login");
        else throw new Error("Ошибка загрузки логов");
      }
      const logsData = await response.json();
      setLogs(Array.isArray(logsData.logs) ? logsData.logs : []);
      setTotalCount(logsData.total_count || 0);
      setTotalPages(Math.ceil((logsData.total_count || 1) / logsPerPage));
    } catch (error) {
      setError("Ошибка загрузки данных");
      setLogs([]);
    } finally {
      setLoading(false);
    }
  }, [logsPerPage, currentPage, startDate, endDate, searchQuery, navigate]);

const fetchStats = useCallback(async () => {
    try {
        const response = await fetch("/api/googlebot-stats");
        if (!response.ok) {
            throw new Error("Ошибка загрузки статистики");
        }
        const statsData = await response.json();
        setStats(statsData);
    } catch (error) {
        console.error("Ошибка загрузки статистики:", error);
    }
}, []);


  
  const handleDeleteSelected = async () => {
    if (selectedLogs.length === 0) return;
    const isConfirmed = window.confirm("Уверены ли вы, что хотите удалить выбранные записи?");
    if (!isConfirmed) return;

    try {
      const token = localStorage.getItem("token");
      const logIdsArray = selectedLogs.map(Number);

      const response = await fetch('/api/googlebot-logs', {
        method: 'DELETE',
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(logIdsArray),
      });

      if (!response.ok) {
        throw new Error("Ошибка удаления записей");
      }

      alert("Удаление выполнено успешно!");
      setSelectedLogs([]);
      fetchData();
    } catch (error) {
      console.error("Ошибка при удалении:", error);
      alert("Ошибка удаления: " + error.message);
    }
  };

  return (

   <>   <Card>
            {error && <Alert variant="danger">{error}</Alert>}

<Card.Header className="d-flex align-items-center gap-2">
  <Button variant="light" size="sm" onClick={() => navigate(-1)}>
    <IconCaretLeft size={18} />
  </Button>
          <h3 className="card-title">Googlebot Logs</h3>
          <Form.Control
            type="text"
            size="sm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="ms-2 d-inline-block w-auto"
            placeholder="Search logs..."
          />
        </Card.Header>
        <Card.Body className="border-bottom py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-secondary">
              Show
              <Form.Control
                as="select"
                size="sm"
                value={logsPerPage}
                onChange={(e) => setLogsPerPage(Number(e.target.value))}
                className="mx-2 d-inline-block w-auto"
              >
                {[10, 50, 100, 500, 1000].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
              entries
            </div>
          </div>
        </Card.Body>
        <div className="table-responsive">
  <div style={{ overflowX: "auto" }}>
          <Table className="table card-table table-vcenter datatable">
            <thead>
              <tr>
                <th className="w-1">
                  <Form.Check type="checkbox" onChange={(e) => setSelectedLogs(e.target.checked ? logs.map(l => l.id) : [])} />
                </th>
                <th>URL</th>
                <th>Дата</th>
                <th>IP</th>
                <th>User-Agent</th>
              </tr>
            </thead>
            <tbody>
              {logs.length === 0 ? (
                <tr><td colSpan="5" className="text-center py-4">Нет данных</td></tr>
              ) : (
                logs.map((log) => (
                  <tr key={log.id}>
                    <td>
                      <Form.Check type="checkbox" checked={selectedLogs.includes(log.id)}
                        onChange={(e) => {
                          setSelectedLogs((prev) =>
                            e.target.checked ? [...prev, log.id] : prev.filter((id) => id !== log.id)
                          );
                        }}
                      />
                    </td>
                    <td style={{ whiteSpace: "normal", wordBreak: "break-word", minWidth: "300px", maxWidth: "100%", flexGrow: 1 }}>{log.url}</td>
                    <td className="text-center">{log.timestamp.split(", ")[0]}<br />{log.timestamp.split(", ")[1]}</td>
                    <td>{log.ip}</td>
                    <td style={{ whiteSpace: "normal", wordBreak: "break-word", width: "375px", flexShrink: 0 }}>{log.user_agent}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <Button variant="danger" onClick={handleDeleteSelected} disabled={selectedLogs.length === 0}>
            Remove
          </Button>
          
          <Pagination className="m-0">
            <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>«</Pagination.Prev>
            {getPaginationPages(currentPage, totalPages).map((page, i) =>
              page === "..." ? (
                <Pagination.Ellipsis key={`ellipsis-${i}`} disabled />
              ) : (
                <Pagination.Item
                  key={`page-${page}`}
                  active={page === currentPage}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Pagination.Item>
              )
            )}
            <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>»</Pagination.Next>
          </Pagination>
    
        </Card.Footer>
      </Card></>
  );
};

export default GooglebotLogs;

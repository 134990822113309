
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../utils/auth";
import {
  IconHome,
  IconList,
  IconSettings,
  IconUser,
  IconLogout,
  IconUsers,
  IconChevronLeft,
  IconChevronRight,
  IconSquareRoundedChevronsLeft,
  IconBrandGoogle
} from "@tabler/icons-react";

const Sidebar = ({ logout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [user, setUser] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 993);

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUser();
      setUser(userData);
    };

    const handleResize = () => {
      const desktop = window.innerWidth > 993;
      setIsDesktop(desktop);
      if (!desktop) setIsCollapsed(false);
    };

    window.addEventListener("resize", handleResize);
    fetchUser();
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const iconSize = 36;
  const displayName = user?.first_name || user?.email || "Username";

  return (
    <aside className={`navbar navbar-vertical navbar-expand-lg${isCollapsed && isDesktop ? " sidebar-collapsed" : ""}`} data-bs-theme="dark">
      <div className="container-fluid sidebar-text">
        {/* Mobile toggle */}
        <button
          className={`navbar-toggler ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Logo */}
        {!isCollapsed && (
          <div className="navbar-brand navbar-brand-autodark text-center d-block">
            <a href="/">
              <img src="/images/logo.webp" alt="IndexBooster" className="navbar-brand-image" width="110" height="32" />
            </a>
          </div>
        )}

        {/* Sidebar nav */}
        <div className={`navbar-collapse collapse ${isOpen ? "show" : ""}`} id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                <span className="nav-link-icon"><IconHome size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Dashboard</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/indexing">
                <span className="nav-link-icon"><IconList size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Indexing Control</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/logs">
                <span className="nav-link-icon"><IconBrandGoogle size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Googlebot Logs</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/settings">
                <span className="nav-link-icon"><IconSettings size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Settings</span>}
              </Link>
            </li>

            <hr className="my-3 border-light" />
            {!isCollapsed && (
              <li className="nav-item">
                <div className="nav-link text-muted">Balance: {user?.credits ?? 0} credits</div>
              </li>
            )}
            {user?.role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link" to="/users">
                  <span className="nav-link-icon"><IconUsers size={iconSize} /></span>
                  {!isCollapsed && <span className="nav-link-title">All Users</span>}
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link className="nav-link" to="/profile">
                <span className="nav-link-icon"><IconUser size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Edit Profile</span>}
              </Link>
            </li>
            <li className="nav-item">
              <button className="nav-link text-danger w-100 text-start" onClick={logout}>
                <span className="nav-link-icon"><IconLogout size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title">Logout</span>}
              </button>
            </li>
            {isDesktop && (
              <li className="nav-item mt-3">
                <button
                  className="nav-link w-100 text-start text-secondary"
                  onClick={() => { setIsCollapsed(!isCollapsed); setIsOpen(false); }}
                >
                  <span className="nav-link-icon"><IconSquareRoundedChevronsLeft size={iconSize} /></span>
                  {!isCollapsed && <span className="nav-link-title">Collapse Menu</span>}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

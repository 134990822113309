const TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh_token";

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setToken = (token, refreshToken) => {
  localStorage.setItem(TOKEN_KEY, token);
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const refreshToken = async () => {
  try {
    const refreshTokenValue = getRefreshToken();
    if (!refreshTokenValue) {
      console.warn("Попытка обновления токена без refresh_token.");
      return false;
    }

    console.log("Отправляем refresh_token для обновления:", refreshTokenValue);

    const response = await fetch("/api/auth/refresh", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh_token: refreshTokenValue }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Ошибка обновления токена:", errorText);
      removeToken();
      return false;
    }

    const data = await response.json();
    setToken(data.access_token, data.refresh_token);
    return true;
  } catch (error) {
    console.error("Ошибка при обновлении токена:", error);
    return false;
  }
};

export const getUser = async () => {
  try {
    const response = await fetchWithAuth("/api/auth/profile");
    if (!response.ok) throw new Error("Ошибка загрузки профиля");
    return await response.json();
  } catch (error) {
    console.error("Ошибка получения пользователя:", error);
    return null;
  }
};


export const logout = () => {
  removeToken();
  window.location.href = "/login";
};

export const isAuthenticated = () => {
  return !!getToken();
};

export const fetchWithAuth = async (url, options = {}) => {
  let token = getToken();

  if (!token) {
    const refreshed = await refreshToken();
    if (!refreshed) {
      removeToken();
      window.location.href = "/login";
      throw new Error("Не удалось обновить токен. Пожалуйста, войдите снова.");
    }
    token = getToken();
  }

  // 🛠️ Исправляем заголовки
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // ✅ Всегда JSON
  };

  const response = await fetch(url, { ...options, headers });

  if (response.status === 401) {
    console.warn("Токен истек, пробуем обновить...");
    const refreshed = await refreshToken();
    if (!refreshed) {
      removeToken();
      window.location.href = "/login";
      throw new Error("Сессия истекла. Перезайдите в систему.");
    }
    token = getToken();
    return fetch(url, { ...options, headers: { ...headers, Authorization: `Bearer ${token}` } });
  }

  return response;
};



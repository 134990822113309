import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import GooglebotLogs from "./pages/GooglebotLogs";
import IndexingControl from "./pages/IndexingControl";
import Settings from "./pages/Settings";
import AddProject from "./pages/AddProject";
import ProjectView from "./pages/ProjectView";
import EditProject from "./pages/EditProject";
import AddLinks from "./pages/AddLinks";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Contactus from "./pages/Contactus";
import Register from "./pages/Register";
import APIbooster from "./pages/APIbooster";
import Profile from "./pages/Profile";
import Users from "./pages/Users";
import UserProjects from "./pages/UserProjects";
import { refreshToken } from "./utils/auth";
import { logout } from "./utils/auth"; // убедись, что путь правильный

function App() {
  useEffect(() => {
    const interval = setInterval(refreshToken, 10 * 60 * 1000); // Обновление токена каждые 10 минут
    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
         <Route path="/registration" element={<Register />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
  path="/users"
  element={
    <ProtectedRoute>
      <Layout logout={logout}>
        <Users />
      </Layout>
    </ProtectedRoute>
  }
/>
<Route
  path="/user/:userId/projects"
  element={
    <ProtectedRoute>
      <Layout logout={logout}>
        <UserProjects />
      </Layout>
    </ProtectedRoute>
  }
/>
        <Route
          path="/indexing"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <IndexingControl />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/logs"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <GooglebotLogs />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <Settings />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/indexing/add"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <AddProject />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/project/:id"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <ProjectView />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/project/:id/edit"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <EditProject />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/project/:id/add-links"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <AddLinks />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/contact-us"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <Contactus />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/api-booster"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <APIbooster />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout logout={logout}>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
